<template>
  <section class="rounded-tr-large bg-beige-50 lg:rounded-tr-[10rem]">
    <div class="container grid auto-rows-min grid-cols-4 gap-4 gap-y-16 py-24 md:hidden">
      <img
        src="~/assets/img/windmolen.png"
        class="col-span-2 row-span-1"
      >
      <img
        src="~/assets/img/fietser.png"
        class="col-span-2 col-start-3 mt-16 w-screen"
      >
      <div class="col-span-4">
        <h2 class="mb-6 text-center">
          Altijd vacatures bij jou in de omgeving
        </h2>
        <div class="flex flex-col items-center justify-center ">
          <div class="flex flex-wrap justify-center gap-2">
            <NuxtLink
              v-for="item in locations"
              :key="item"
              :to="item.slug"
            >
              <UiTag
                class="cursor-pointer"
                type="outline"
              >
                {{ item?.title }}
              </UiTag>
            </NuxtLink>
          </div>
        </div>
      </div>

      <img
        src="~/assets/img/station-amsterdam.png"
        class="col-span-2 row-span-2"
      >
      <img
        src="~/assets/img/persoon-op-perron.png"
        class="col-span-2 row-span-2 mt-6"
      >
    </div>

    <div class="container hidden w-screen grid-cols-12 grid-rows-12 p-16 md:grid">
      <img
        src="~/assets/img/windmolen.png"
        class="col-span-2 row-span-4 row-start-2"
      >
      <img
        src="~/assets/img/fietser.png"
        class="col-span-3 col-start-4 row-span-2"
      >
      <img
        src="~/assets/img/snelweg.png"
        class="col-span-2 col-start-11 row-span-4 row-start-3"
      >
      <div class="col-span-8 col-start-3 row-span-4 row-start-4">
        <div class="flex flex-col items-center justify-center ">
          <div
            class=" max-w-2xl"
          >
            <h2 class="mb-6 text-center leading-tight">
              Altijd vacatures bij jou in de omgeving
            </h2>
            <div class="flex flex-wrap justify-center gap-2">
              <NuxtLink
                v-for="item in locations"
                :key="item"
                :to="item.slug"
              >
                <UiTag
                  class="cursor-pointer !border-neutral-900"
                >
                  {{ item?.title }}
                </UiTag>
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
      <img
        src="~/assets/img/persoon-op-perron.png"
        class="col-span-3 col-start-2 row-span-4 row-start-8"
      >
      <img
        src="~/assets/img/station-amsterdam.png"
        class="col-span-5 col-start-7 row-span-5 row-start-9"
      >
    </div>
  </section>
</template>

<script setup lang="ts">
import {useLocationQuery} from '~/graphql/graphql';

const {result} = useLocationQuery();
const locations = computed(() => result.value?.location ?? []);
</script>
