<template>
  <section class="rounded-tr-large bg-black lg:rounded-tr-[10rem]">
    <div class="container grid grid-cols-4 gap-8 py-24 md:grid-cols-12 md:py-32">
      <div class="col-span-4 flex flex-col justify-center text-white md:col-span-5 md:col-start-2">
        <h2>
          Waarom Next Recruitment?
        </h2>
        <p class="mb-8 mt-4 text-lg !leading-relaxed lg:text-2xl">
          Next maakt zich sterk voor de bemiddeling binnen kantoor- en bureaufuncties, MBO 4+
          en leidinggevende functies.
        </p>
        <UiLinkNavigation :to="{name: 'werkgevers'}">
          Voor opdrachtgevers
        </UiLinkNavigation>
      </div>
      <div class="col-span-4 mt-24 md:col-span-5 md:col-start-7">
        <RecruiterImage
          invert
          :image="result?.active_image?.url"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {useRandomRecruiterQuery} from '~/graphql/graphql';

const recruiter = useRandomRecruiterQuery();
const result = computed(() => recruiter.result.value?.randomRecruiter ?? []);
</script>
