<template>
  <div>
    <section class="relative">
      <div class="pointer-events-none absolute -left-3/4 bottom-1/4 h-[400px] w-[400px] rotate-45 rounded-[100px] bg-black sm:h-[800px] sm:w-[800px] md:-left-1/2 md:bottom-auto md:h-[1200px] md:w-[1200px] lg:rounded-[200px] xl:-inset-y-1/2 xl:h-[1600px] xl:w-[1600px]" />
      <div class="relative">
        <header class="grid lg:grid-cols-[1fr_minmax(80rem,_1fr)_1fr]">
          <div class="col-span-3 my-12 grid grid-cols-4 items-center gap-8 lg:col-span-2 lg:col-start-2 lg:mb-40 lg:mt-32 lg:grid-cols-12">
            <div class="col-span-4 mx-4 flex flex-col items-center lg:items-start">
              <h1 class="mb-8 text-center text-white lg:text-left">
                De volgende stap in jouw carrière
              </h1>
              <UiLinkNavigation to="/vacatures">
                Bekijk vacatures
              </UiLinkNavigation>
            </div>
            <div class="col-span-4 flex gap-10 lg:col-span-8">
              <Swiper
                v-if="professions"
                :slides-per-view="1.2"
                :breakpoints="{768: {slidesPerView: 2.2},}"
                class="h-fit w-full"
              >
                <SwiperSlide
                  v-for="profession in professions"
                  :key="profession?.title"
                  class="px-5 !pb-0"
                >
                  <NuxtLink
                    :to="profession?.slug"
                    class="group"
                  >
                    <UiImagePortrait
                      v-if="profession?.active_image"
                      :src="profession?.active_image.url"
                    >
                      <button class="absolute right-4 top-4 flex rounded-full bg-white p-4  md:hidden lg:right-8 lg:top-8">
                        <Icon
                          name="mdi:arrow-top-right"
                          class="h-6 w-6"
                        />
                      </button>

                      <div class="flex flex-col flex-nowrap">
                        <p class="absolute inset-x-4 bottom-4 w-fit font-technor text-2xl font-medium text-white transition-all group-hover:bottom-10 lg:inset-x-8 lg:bottom-8">
                          {{ profession?.title }}
                        </p>
                        <button class="absolute bottom-6 right-6 rounded-full bg-white p-4 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                          <Icon
                            name="mdi:arrow-top-right"
                            class="h-6 w-6"
                          />
                        </button>
                      </div>
                    </UiImagePortrait>
                  </NuxtLink>
                </SwiperSlide>
                <div class="col-span-4 mt-8 flex justify-center lg:col-span-11 lg:mx-36 lg:justify-end">
                  <UiSwiperControls />
                </div>
              </Swiper>
            </div>
          </div>
        </header>
      </div>
    </section>

    <SectionApplicationList class="relative" />
    <div class="bg-white">
      <UiNearbySection />
    </div>
    <div class="bg-beige-50">
      <SectionCallToAction />
    </div>

    <section class="bg-black">
      <SectionCardGrid class="container">
        <UiIconCard
          title="Snel schakelen"
          text="Onze diensten bieden persoonlijke begeleiding en advies, met als doel om je te assisteren bij het vinden van de volgende stap in jouw carrière."
          icon="snel-schakelen"
        />
        <UiIconCard
          title="Persoonlijk"
          text="Onze diensten bieden persoonlijke begeleiding en advies, met als doel om je te assisteren bij het vinden van de volgende stap in jouw carrière."
          icon="persoonlijk"
        />
      </SectionCardGrid>
    </section>
    <SectionAboutUs
      class="bg-black"
      title="Wij staan klaar voor jouw succes"
      text="Next maakt zich sterk voor de bemiddeling binnen kantoor- en bureaufuncties, MBO 4+ en leidinggevende functies."
      :button="{
        label: 'Werken bij Next Recruitment',
        to: {name: 'vacatures'},
      }"
      half
    />
    <section class="bg-black ">
      <div class="container grid grid-cols-2 gap-4 lg:gap-10">
        <img
          src="~/assets/img/next-recruitment-image-overons-links.png"
          class="w-full"
        >
        <img
          src="~/assets/img/next-recruitment-image-overons-rechts.png"
          class="w-full"
        >
      </div>
    </section>
    <SectionReferences
      v-if="page.template.references"
      :references="page.template.references"
    />
  </div>
</template>

<script setup lang="ts">
import {useProfessionsQuery} from '~/graphql/graphql';

definePageMeta({
  middleware: ['sofie-page'],
  header: {
    absolute: true,
  },
});

const page = useSofiePage();

const {result: professionsResults} = useProfessionsQuery();
const professions = computed(() => professionsResults.value?.profession ?? []);
</script>
