<template>
  <div class="rounded-tr-large bg-white pb-6 pt-16 lg:rounded-tr-[10rem] ">
    <section class="container">
      <h1 class="mb-3 text-3xl lg:text-6xl">
        Ga aan de slag als
      </h1>
      <p class="text-2xl">
        Bekijk onze vacatures voor jouw interesses
      </p>
      <div class="grid grid-cols-4 gap-12 lg:grid-cols-12">
        <ul class="divide-gray col-span-4 divide-y pt-6 lg:col-span-7">
          <li
            v-for="(profession, index) in professions"
            :key="index"
            class="group py-4 lg:py-10"
            @mouseenter="selectedProfession = profession"
          >
            <div
              v-if="profession?.slug && profession?.title"
              class="flex items-center justify-between"
            >
              <p class="font-technor text-2xl font-medium lg:text-3xl">
                {{ profession.title }}
              </p>
              <div class="hidden md:block">
                <UiLinkNavigation
                  :to="profession.slug"
                  class="opacity-0 transition-all duration-300 group-hover:opacity-100"
                >
                  Bekijk vacatures
                </UiLinkNavigation>
              </div>
              <div class="block md:hidden">
                <NuxtLink
                  :to="profession.slug"
                  class="bg-orange flex rounded-full p-3"
                >
                  <Icon
                    name="mdi:arrow-top-right"
                    class="h-6 w-6"
                  />
                </NuxtLink>
              </div>
            </div>
          </li>
        </ul>
        <div class="col-span-4 hidden lg:col-start-9 lg:block">
          <UiImagePortrait :src="selectedProfession?.active_image.url" />
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">

import {useProfessionsQuery} from '~/graphql/graphql';

const {result} = useProfessionsQuery({limit: 4});
const professions = computed(() => result.value?.profession ?? []);
const selectedProfession = ref(professions.value?.[0]);
</script>
