<template>
  <div class="flex gap-2">
    <button
      :disabled="swiper.isBeginning"
      class="rounded-full border px-4 py-3 transition-all duration-300 hover:px-6 disabled:cursor-not-allowed disabled:border-neutral-500 disabled:text-neutral-400 disabled:hover:px-4"
      :class="[colorVariants[color]]"
      @click="swiper.slidePrev()"
    >
      <Icon
        name="mdi:arrow-left"
        class="flex h-6 w-6"
      />
    </button>
    <button
      :disabled="swiper.isEnd"
      class="rounded-full border px-4 py-3 transition-all duration-300 hover:px-6 disabled:cursor-not-allowed disabled:border-neutral-500 disabled:text-neutral-400 disabled:hover:px-4"
      :class="[colorVariants[color]]"
      @click="swiper.slideNext()"
    >
      <Icon
        name="mdi:arrow-right"
        class="flex h-6 w-6"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
import {useSwiper} from 'swiper/vue';

withDefaults(defineProps<{
  color?: 'white' | 'black'
}>(), {
  color: 'white',
});

const swiper = useSwiper();

const colorVariants = {
  white: 'border-white text-white',
  black: 'border-neutral-900 text-neutral-900',
};
</script>
